.userChat {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #ffffff;
  cursor: pointer;
}

.userChatImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.userChat:hover {
  background-color: #2e57b0;
}

.userChatInfo span {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;
}

.userChatInfo p {
  margin-top: 0px;
  font-size: 14px;
  color: lightgray;
}

.chatInfo {
  height: 80px;
  background-color: #5389ff;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 10px;
  color: lightgray;
}

.messages {
  background-color: #eaf0ff;
  padding: 10px;
  height: calc(100% - 160px);
  overflow: scroll;
}

.input {
  height: 80px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input input {
  width: 90%;
  border: none;
  outline: none;
  color: #2f2d52;
  font-size: 18px;
}

.input input::placeholder {
  color: darkgray;
}

.send {
  display: flex;
  align-items: center;
  gap: 10;
}

.send button {
  border: none;
  padding: 10px 15px;
  color: #ffffff;
  background-color: #8da4f1;
  border-radius: 20px;
  width: 80px;
}

.send button:hover {
  cursor: pointer;
  background-color: #5d5b8d;
}

.message {
  display: flex;
  gap: 20px;
}

.messageInfo {
  display: flex;
  flex-direction: column;
  color: gray;
  font-weight: 300;
  margin-bottom: 20px;
}

.messageInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.messageInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.messageContent {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.messageContent p {
  background-color: #f9fafb;
  padding: 10px 20px;
  border-radius: 0px 10px 10px 10px;
  max-width: max-content;
}

.owner {
  flex-direction: row-reverse;
}

.owner p {
  background-color: #5389ff;
  color: #ffffff;
  border-radius: 10px 0px 10px 10px;
}

/* Chat side bar styling */

.chatsContainer {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.searchBar {
  margin-top: 5px;
  margin-bottom: 20px;
}

.searchInput {
  width: 100%;
  padding: 18px 100px 15px 50px;
  font-size: 16px;
  border-radius: 10px;
  border: none;
  background-image: url('https://cdn-icons-png.flaticon.com/512/622/622669.png');
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 17px 17px;
  color: #555;
}

.roomsList {
  flex: 1;
  overflow-y: auto;
}

.roomButton {
  background-color: #3d69c8;
  border-width: 0;
  width: 100%;
  margin-bottom: 10px;
}

.userChat {
  display: flex;
  align-items: center;
}

.userChatImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.userChatInfo {
  display: flex;
  flex-direction: column;
}

.sidebarContainer {
  flex: 1;
  background-color: #3d69c8;
  height: 100vh; /* Make sure sidebar takes the full height */
  overflow-y: hidden; /* Hide overflow to manage scrolling in child elements */
}
